<template>
  <div class="project-box">
    <div class="button-wrapper">
      <img :src='require("@/assets/" + name.toLowerCase() + ".png")' :alt="name + ' logo'" class="logo"/>
    </div>
    <h2 class="name">{{ name }}</h2>
    <p class="description">{{ description }}</p>
    <div class="tags-wrapper">
      <TagComponent v-for="tag in tags" v-bind:key='tag.content' :color="tag.color" :content="tag.content"/>
    </div>
    <div class="button-wrapper">
      <LinkComponent content="navštíviť" :link="link"/>
    </div>
  </div>
</template>

<script>
import LinkComponent from "@/components/LinkComponent";
import TagComponent from "@/components/TagComponent";
export default {
  name: "ProjectComponent",
  components: {TagComponent, LinkComponent},
  props: {
    name: Text,
    description: Text,
    tags: Array,
    link: Text,
  },
}
</script>

<style scoped>
.project-box {
  width: 300px;
  border: 2px solid black;
  padding: 30px 35px;
  text-align: left;
  display: inline-block;
  vertical-align:top;
  @apply mx-1 sm:mx-3 my-1 sm:my-3;
}
.name {
  font-family: 'Mohave', sans-serif;
  font-size: 24px;
  font-weight: 600;
  @apply mb-0 pb-0;
}
.description {
  font-family: 'Mohave', sans-serif;
  font-size: 24px;
  font-weight: normal;
  @apply pb-2;
}
.logo {
  margin-bottom: 32px;
  max-height: 56px;
  display: inline-block;
}
.button-wrapper {
  width: 100%;
  text-align: center;
}
.tags-wrapper {
  margin-bottom: 20px;
  min-height: 74px;
}
</style>