<template>
  <a class="button" :href="link" target="_blank">
    {{ content }}
  </a>
</template>

<script>
export default {
  name: "LinkComponent",
  props: {
    content: Text,
    link: Text,
  }
}
</script>

<style scoped>
.button {
  font-family: 'Mohave', sans-serif;
  font-size: 24px;
  padding: 10px 15px;
  border: 2px solid black;
  box-shadow: 10px 10px 0 0 black;
  display: inline-block;
  transition: all .05s ease-in-out;
}
.button:hover {
  box-shadow: 0px 0px 0 0 black;
  transform: translate(10px, 10px);
}
</style>