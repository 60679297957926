<template>
  <div class="container">
    <h1 id="title">marweb</h1>
    <div class="project-wrapper">
      <ProjectComponent
        name="HostNow"
        description="Moderný webhosting"
        :tags="[
          {
            content: 'JAVA',
            color: 'light-orange',
          },
          {
            content: 'JS',
            color: 'light-green',
          },
          {
            content: 'LARAVEL',
            color: 'red'
          },
          {
            content: 'PHP',
            color: 'phprle'
          },
          {
            content: 'LINUX',
            color: 'ubuntu'
          }
        ]"
        link="https://hostnow.cz/"
    />
      <ProjectComponent
          name="Hyperbola"
          description="Edukačný portál"
          :tags="[
          {
            content: 'GHOST CMS',
            color: 'ghost'
          },
          {
            content: 'LARAVEL',
            color: 'red'
          },
          {
            content: 'PHP',
            color: 'phprle'
          },
          {
            content: 'VUE.JS',
            color: 'vueen'
          }
        ]"
          link="https://hyperbola.sk/"
      />
      <ProjectComponent
          name="TaskMate"
          description="Správca úloh"
          :tags="[
          {
            content: 'LARAVEL',
            color: 'red'
          },
          {
            content: 'PHP',
            color: 'phprle'
          },
          {
            content: 'EOL 2021',
            color: 'inactive'
          },
        ]"
          link="http://web-skkg1t1.hstnw.eu/"
      />
      <ProjectComponent
          name="KMBA"
          description="Stránka organizácie"
          :tags="[
          {
            content: 'WORDPRESS',
            color: 'wordpress'
          },
          {
            content: 'PHP',
            color: 'phprle'
          },
        ]"
          link="http://mladezba.sk/"
      />
    </div>

    <p class="description-self">
      Sme dvaja inovatívni, ambiciozni študenti pre ktorých je programovanie vášňou. Prekonávame prekážky a radi objavujeme nové technológie.
    </p>
    <LinkComponent link="mailto:info@marweb.eu" content="kontaktovať"/>

  </div>
</template>

<script>

import ProjectComponent from "@/components/ProjectComponent";
import LinkComponent from "@/components/LinkComponent";
export default {
  name: 'App',
  components: {
    LinkComponent,
    ProjectComponent
  }
}
</script>

<style>
#title {
  font-family: 'Montserrat Alternates', sans-serif;
  @apply mb-8 lg:text-9xl text-6xl sm:text-8xl;
}
.container {
  width: 100%;
  text-align: center;
  @apply py-20 sm:px-10;
}
.project-wrapper {
}
.description-self {
  font-family: 'Mohave', sans-serif;
  font-size: 24px;
  @apply lg:mx-56 mb-5 mt-10;
}
</style>
