<template>
  <div :class="'tag ' + color">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "TagComponent",
  props: {
    color: Text,
    content: Text,
  }
}
</script>

<style scoped>
.tag {
  padding-right: 10px; padding-left: 10px;
  font-family: 'Mohave', sans-serif;
  font-size: 20px;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
}
.red {
  background-color: #ff2d20;
}
.vue {
  background-color: #42b883;
}
.phprle {
  background-color: #7377ad;
}
.light-green {
  background-color: #87bf00;
}
.light-orange {
  background-color: #f58219;
}
.vueen {
  background-color: #60ae78;
}
.inactive {
   background-color: #bbbbbb;
}
.ghost {
  background-color: #111827;
  color: white;
}
.wordpress {
  background-color: #0073aa;
  color: white;
}
.ubuntu {
  background-color: #E95420;
}
</style>